import React from 'react';
import * as LogicService from "../services/LogicService";

export default function PdfViewerComponent() {

    let companyInUse = LogicService.getCompanyInUse();
    let docName = "Contract.pdf"
    if (companyInUse.ro === "51014507") {
        docName = "Contract_RIG.pdf"
    }
    if (companyInUse.ro === "51517282") {
        docName = "Contract_RIS.pdf"
    }
    return (
        <iframe
            title={"Contract PDF"}
            src={'/web/viewer.html?file=/' + docName}
            frameBorder="0" height="100%" width="100%">
        </iframe>


        // <iframe
        //     src="https://docs.google.com/viewerng/viewer?url=https://caziere.ro/Contract.pdf&embedded=true"
        //     frameBorder="0" height="100%" width="100%">
        // </iframe>
    );
}