import {Icon} from '@iconify/react';
// material
import {styled} from '@mui/material/styles';
import {Box, Typography} from '@mui/material';
// utils
import * as React from "react";
import details from "@iconify/icons-eva/menu-2-fill";
import briefcase from "@iconify/icons-eva/briefcase-outline";
import phone from "@iconify/icons-eva/phone-call-outline";
import email from "@iconify/icons-eva/email-outline";
import pin from "@iconify/icons-eva/pin-outline";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({theme}) => ({
    textAlign: 'left',

    [theme.breakpoints.up('lg')]: {
        height: "100%",
        textAlign: 'left',
        verticalAlign: 'middle',
        padding: theme.spacing(0, 0),
        color: theme.palette.primary.darker,
    }
}));

export default function FooterInfoOne({title, text, photo}) {

    return (
        <RootStyle>
            <Typography variant="h5" sx={{marginBottom: "15px"}}>
                Contact
            </Typography>

            <Box sx={{display: 'flex', marginTop: '10px'}}>
                <Icon icon={briefcase} width={24} height={24}/>
                <Typography variant="body1" sx={{marginLeft: "5px"}}>
                    RIGESERA SRL
                </Typography>
            </Box>

            <Box sx={{display: 'flex', marginTop: '8px'}}>
                <Icon icon={details} width={24} height={24}/>
                <div sx={{display: 'block'}}>
                    <Typography variant="body1" sx={{marginLeft: "5px"}}>
                        CUI 51014507
                    </Typography>
                    <Typography variant="body1" sx={{marginLeft: "5px"}}>
                        J2024048041009
                    </Typography>
                </div>
            </Box>

            {/*<Box sx={{display: 'flex', marginTop: '10px'}}>*/}
            {/*    <Icon icon={phone} width={24} height={24}/>*/}
            {/*    <Typography variant="body1" sx={{marginLeft: "5px"}}>*/}
            {/*        +40742785093*/}
            {/*    </Typography>*/}
            {/*</Box>*/}

            <Box sx={{display: 'flex', marginTop: '15px'}}>
                <Icon icon={email} width={24} height={24}/>
                <Typography variant="body1" sx={{marginLeft: "5px"}}>
                    contact@caziere.ro
                </Typography>
            </Box>

            {/*<Box sx={{display: 'flex', marginTop: '15px'}}>*/}
            {/*    <Icon icon={pin} width={24} height={24}/>*/}
            {/*    <Typography variant="body1" sx={{marginLeft: "5px"}}>*/}
            {/*        Str. Rozelor, Nr. 65, Nicolina, Iași*/}
            {/*    </Typography>*/}
            {/*</Box>*/}

        </RootStyle>
    );
}
