import * as React from 'react';
import {useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import * as CommunicationService from '../../../services/CommunicationService'
import * as LogicService from '../../../services/LogicService'
import {DOCUMENTS_DETAILS} from '../../../services/LogicService'
// material
import {Box, Button, Grid, Stack, Typography} from '@mui/material';
import DocumentUploader from "./DocumentUploader";
import * as AlertMapper from "../../../services/AlertMapper";
// ----------------------------------------------------------------------


export default function OrderComplete({translate}) {
    const navigate = useNavigate();

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    let order = LogicService.getOrder();
    let docs = LogicService.getUploadedDocuments();

    //fallback
    if (order == undefined || order == null) {
        order = {
            userType: null,
            necessaryDocuments: []
        }
    }

    const [id_card_var, setIdCardUploaded] = useState(false);
    const [id_card_back_var, setIdCardBackUploaded] = useState(false);
    const [person_with_id_var, setPersonWithIdCardUploaded] = useState(false);
    const [business_contract_var, setBusinessContractUploaded] = useState(false);
    const [registration_certificate_var, setRegistrationCertificate] = useState(false);
    const [power_of_attorney, setPowerOfAttorney] = useState(false);
    const [anexa_38, setAnexa38] = useState(false);

    let necessaryDocuments = {}

    const getDetailsByKey = (key) => {
        switch (key) {
            case DOCUMENTS_DETAILS.ID_CARD.key: {
                return [id_card_var, setIdCardUploaded];
            }
            case DOCUMENTS_DETAILS.ID_CARD_BACK.key: {
                return [id_card_back_var, setIdCardBackUploaded];
            }
            case DOCUMENTS_DETAILS.PERSON_WITH_ID.key: {
                return [person_with_id_var, setPersonWithIdCardUploaded];
            }
            case DOCUMENTS_DETAILS.BUSINESS_CONTRACT.key: {
                return [business_contract_var, setBusinessContractUploaded];
            }
            case DOCUMENTS_DETAILS.REGISTRATION_CERTIFICATE.key: {
                return [registration_certificate_var, setRegistrationCertificate];
            }
            case DOCUMENTS_DETAILS.POWER_OF_ATTORNEY.key: {
                return [power_of_attorney, setPowerOfAttorney];
            }
            case DOCUMENTS_DETAILS.SRL_ANEXA_38.key: {
                return [anexa_38, setAnexa38];
            }
            default: {
                return [];
            }
        }
    }

    let orderIsBusiness = order.userType === 'business';

    order.necessaryDocuments.forEach(doc => {
        let smallDoc = doc.toLowerCase();
        necessaryDocuments[smallDoc] = {
            ...DOCUMENTS_DETAILS[doc],
            change: getDetailsByKey(smallDoc)[1],
            value: false
        }
        if (orderIsBusiness === true && smallDoc === 'id_card') {
            necessaryDocuments[smallDoc].title += " a administratorului";
        }
    })

    const uploadDocument = (event, objectKey) => {
        let file = event.target.files[0];
        if (file != undefined && file != null) {
            if (!file.name.toLowerCase().endsWith('.pdf') && file.size > 10014207) {
                LogicService.setAlertMessageAndSeverity({
                    message: (translate ? 'The file is too similar. Try again!' : 'Fisierul este mult prea pare. Incercati din nou!'),
                    key: 'anaf_cui_not_found',
                    severity: 'error'
                })
                setDisableAll(false);
                return;
            }
        }
        CommunicationService.addDocumentToOrder(order.id, objectKey, file)
            .then((response) => {
                necessaryDocuments[objectKey].change(true);
                LogicService.addUploadedDocuments({
                    key: objectKey,
                    uploaded: true
                })

                docs = LogicService.getUploadedDocuments();
                handleClick();
                if (docs.find(doc => doc.uploaded === false) !== undefined) {
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_UPLOAD);
                }
                setDisableAll(false);
            })
            .catch((error) => {
                let ceva = []
                for (var i = 0; i < localStorage.length; i++) {
                    ceva.push(localStorage.key(i) + ": " + localStorage.getItem(localStorage.key(i)))
                }
                ceva.push("currentURL: " + window.location.href)
                CommunicationService.error(order.id, error.stack.toString(), ceva)
                    .then((response) => {

                        // throw new Error(JSON.stringify({
                        //     responseBody: body,
                        //     code: response.status,
                        //     message: body.message,
                        //     method: method,
                        //     url: url,
                        //     data: data,
                        //     headers: headers
                        // }))

                        try {
                            let message = JSON.parse(error.message).responseBody.message;
                            if (message != "Fișierul încărcat nu trebuie să depășească 20MB. Vă rugăm încercați să încărcați un fișier mai mic."
                                && message != "Full authentication is required to access this resource") {
                                console.log("Message: " + message)
                                toPayment()
                            } else {
                                LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error))
                                setDisableAll(false);
                            }
                        } catch (err) {
                            console.log(err);
                            toPayment()
                        }


                    })
                    .catch((err) => {
                        console.log(err)
                        try {
                            let message = JSON.parse(error.message).responseBody.message;
                            if (message != "Fișierul încărcat nu trebuie să depășească 20MB. Vă rugăm încercați să încărcați un fișier mai mic."
                                && message != "Full authentication is required to access this resource") {
                                toPayment()
                            } else {
                                LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error))
                                setDisableAll(false);
                            }
                        } catch (err) {
                            console.log(err);
                            toPayment()
                        }
                    });
                // LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error))
                // setDisableAll(false);
            });
    }

    const [disableAll, setDisableAll] = useState(false);

    const getDocumentBy = (key, doc) => {
        let alreadyUploaded = getDetailsByKey(key)[0]
        return <Grid item xs={12} sm={6} md={12 / order.necessaryDocuments.length}>
            <DocumentUploader
                isBussines={order.userType === 'business'}
                key={key}
                disabled={alreadyUploaded}
                disableAll={disableAll}
                document={{
                    key: key,
                    title: (translate ? doc.title_en : doc.title),
                    onChange: (event) => {
                        setDisableAll(true);
                        uploadDocument(event, key);
                    }
                }}/>
        </Grid>
    }


    const toPayment = () => {
        LogicService.updateOrderState("SIGNATURE");
        navigate(translate ? '/en' : '/');
    }

    const back = () => {
        LogicService.resetSession();
        navigate(translate ? '/en' : '/');
    }

    function handleClick() {
        forceUpdate();
    }

    let nextDisabled = (
        docs.length === order.necessaryDocuments.length
        && !docs.map(doc => doc.uploaded).includes(false)
    );
    let nextWithoutPersonWithId = (
        docs.filter(nd => nd.key !== 'person_with_id').length === order.necessaryDocuments.filter(nd => nd !== 'PERSON_WITH_ID').length
        && !docs.map(doc => doc.uploaded).includes(false)
    );

    return (
        <Box>
            <Grid container spacing={5}>
                {
                    Object.keys(necessaryDocuments)
                        .map(key => getDocumentBy(key, necessaryDocuments[key]))
                }
            </Grid>
            {/*{*/}
            {/*    order.userType !== 'business' ? null : <Box sx={{marginTop: "30px", display: "flex", justifyContent: "center"}}>*/}
            {/*        <Typography variant="subtitle1">*/}
            {/*            Pentru asistență referitoare la documentele necesare, sunați la 0742785093*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*}*/}
            <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}} spacing={2} alignSelf={"center"}>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color={'warning'}
                    onClick={back}
                >
                    {translate? 'Home' : 'Acasă'}
                </Button>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={toPayment}
                    disabled={!(nextDisabled || nextWithoutPersonWithId)}
                >
                    {translate? 'To the last step' : 'Către ultimul pas'}
                </Button>
            </Stack>
        </Box>
    );
}
