import * as React from 'react';
import {useEffect, useState} from 'react';
import {Form, FormikProvider} from 'formik';
import {useNavigate} from 'react-router-dom';
import * as LogicService from '../../../services/LogicService'
// material
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup, Link,
    Stack,
    TextField,
    ToggleButton,
    Typography
} from '@mui/material';
import {ToggleButtonGroup} from '@mui/lab';
import CountrySelect from "./CountrySelect";
import * as Countries from "../../../constants/Countries";
import * as AlertMapper from "../../../services/AlertMapper";
import "./OrderForm.css"
import * as CommunicationService from "../../../services/CommunicationService";
import TextSelectInput from "./TextSelectInput";
import * as Siruta from "../../../constants/Siruta";
import TextSelectInputPlainString from "./TextSelectInputPlainString";
import AnafFiscalSearchFields from "./AnafFiscalSearchFields";

// ----------------------------------------------------------------------


export default function OrderFiscalInfoV2({urlPreffix, translate}) {
    const navigate = useNavigate();

    if (urlPreffix == undefined) {
        urlPreffix = '';
    }

    let order = LogicService.getOrder();
    let shareable = order.shareable === true;
    let details = order.details || {};
    let isForeignCitizen = details.regime === 'ADDRESS_FOREIGN_UE';
    console.log(isForeignCitizen)
    let userDetails = details.userDetails || {};
    let address = details.address || {};
    let countryToChooseAsDefault = Countries.getCountryByName(details.country);

    if (shareable) {
        details = {};
        userDetails = {};
        address = {};
        countryToChooseAsDefault = null;
    }

    // const [sameData, changeSameData] = useState(!shareable);
    const [sameData, changeSameData] = useState(false);

    const [emailField, setEmailField] = useState(details.username);
    const [phoneNumberField, setPhoneNumberField] = useState(details.phoneNumber);
    const [userTypeField, setUserTypeField] = useState(undefined);
    const [firstNameField, setFirstNameField] = useState(userDetails.firstName);
    const [lastNameField, setLastNameField] = useState(userDetails.lastName);
    const [cuiField, setCuiField] = useState(userDetails.cui);

    const [countryField, setCountryField] = useState(countryToChooseAsDefault);

    const [districtField, setDistrictField] = useState(isForeignCitizen ? 'BUCURESTI' : address.district);
    const [cityField, setCityField] = useState(address.city);
    const [streetField, setStreetField] = useState(isForeignCitizen ? 'Principala' : address.street);
    const [detailsField, setDetailsField] = useState(isForeignCitizen ? 'Nr 1' : address.details);
    const [nrRcField, setNrRcField] = useState(null);
    const [bankNameField, setBankNameField] = useState(null);
    const [ibanField, setIbanField] = useState(null);

    const [errorObj, setErrorObj] = useState({
        field: '',
        message: ''
    });

    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [tacCheckBoxEnabled, setTacCheckBoxEnabled] = useState(true);
    const disableTemporarily = () => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 10000);
    }

    // const [ANAF_API_INTEGRATION, SET_ANAF_API_INTEGRATION] = useState(true);

    const getToggleButton = (text, value, key, icon, changeVariable, changeMethod) => {
        return <ToggleButton value={value} key={key} selected={changeVariable}
                             onChange={() => {
                                 changeMethod(!changeVariable);
                             }}>
            <Box component="img" src={icon} width={{xs: 20, sm: 34}}/>
            <Typography variant={{xs: 'body2', sm: 'subtitle1'}} sx={{padding: '0 10px', marginTop: '10px'}}
                        gutterBottom>{text}</Typography>
        </ToggleButton>
    }

    const personSelect = [
        getToggleButton((translate ? 'Person' : 'Persoană fizică'), 'person', 'person', "/static/illustrations/businessman.png"),
        getToggleButton((translate ? 'Business' : 'Persoană juridică'), 'business', 'business', "/static/illustrations/online-shop.png")
    ];

    const handlePersonTypeChange = (event, newUserType) => {
        if (newUserType === 'business') {
            setFirstNameField(null);
        }
        setUserTypeField(newUserType);
    };

    const personTypeControls = {
        value: userTypeField,
        onChange: handlePersonTypeChange,
        exclusive: true,
    };

    const buttonPress = () => {
        if (emailField === null || emailField === undefined || emailField.length < 3) {
            setErrorObj({
                field: 'emailField',
                message: (translate ? 'Email is required' : 'Emailul este necesar')
            })
            return LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'Email is required' : 'Emailul este necesar'),
                key: 'invalid_cui', severity: 'error'
            });
        }

        if (countryField === null || countryField === undefined) {
            setErrorObj({
                field: 'countryField',
                message: (translate ? 'The country is necessary' : 'Tara este necesara')
            })
            return LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The country is necessary' : 'Tara este necesara'),
                key: 'invalid_cui', severity: 'error'
            });
        }

        if (phoneNumberField === null || phoneNumberField === undefined || phoneNumberField.length < 3) {
            setErrorObj({
                field: 'phoneNumberField',
                message: (translate ? 'Phone is required' : 'Telefonul este necesar')
            })
            return LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'Phone is required' : 'Telefonul este necesar'),
                key: 'invalid_cui', severity: 'error'
            });
        }

        if (phoneNumberField.length < 6) {
            setErrorObj({
                field: 'phoneNumberField',
                message: (translate ? 'The phone number is not valid.' : 'Numărul de telefon nu este valid.')
            })
            LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The phone number is not valid.' : 'Numărul de telefon nu este valid.'),
                key: 'invalid_phone',
                severity: 'error'
            });
            return;
        }

        if (personTypeControls === undefined || personTypeControls === null || personTypeControls.value === undefined || personTypeControls.value === null || Boolean(personTypeControls.value === '')) {
            return LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The person type must be selected' : 'Trebuie selectat tipul persoanei'),
                key: 'invalid_cui', severity: 'error'
            });
        }

        if (personTypeControls.value === 'person' && (firstNameField === undefined || firstNameField == null || lastNameField === undefined || lastNameField == null || firstNameField.length < 3 || lastNameField.length < 3)) {
            if (firstNameField === undefined || firstNameField == null || firstNameField.length < 3) {
                setErrorObj({
                    field: 'firstNameField',
                    message: (translate ? 'Name fields must be filled' : 'Trebuie completate câmpurile de nume')
                })
            } else {
                setErrorObj({
                    field: 'lastNameField',
                    message: (translate ? 'Name fields must be filled' : 'Trebuie completate câmpurile de nume')
                })
            }

            LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'Name fields must be filled' : 'Trebuie completate câmpurile de nume'),
                key: 'invalid_name',
                severity: 'error'
            });
            return;
        }

        if (personTypeControls.value === 'business' && (firstNameField === undefined || firstNameField == null || firstNameField.length < 3)) {
            setErrorObj({
                field: 'firstNameField',
                message: (translate ? 'The name of the company must be filled in' : 'Trebuie completat numele firmei')
            })
            LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The name of the company must be filled in' : 'Trebuie completat numele firmei'),
                key: 'invalid_name',
                severity: 'error'
            });
            return;
        }

        if (personTypeControls.value === 'business' && (cuiField === undefined || cuiField == null || cuiField.length < 3)) {
            setErrorObj({
                field: 'cuiField',
                message: (translate ? 'The CUI field must be filled' : 'Trebuie completat câmpul CUI')
            })
            LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The CUI field must be filled' : 'Trebuie completat câmpul CUI'),
                key: 'invalid_cui',
                severity: 'error'
            });
            return;
        }

        if (personTypeControls.value === 'business' && (nrRcField === undefined || nrRcField == null || nrRcField.length < 3)) {
            if (anafSearchedCompany == null) {
                autoSearchCompanyInAnafDB();
                return;
            }
            setErrorObj({
                field: 'nrRcField',
                message: (translate ? 'The registration number from the commercial register must be filled in' : 'Trebuie completat numărul de înregistrare de la registrul comerțului')
            })
            LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The registration number from the commercial register must be filled in' : 'Trebuie completat numărul de înregistrare de la registrul comerțului'),
                key: 'invalid_cui',
                severity: 'error'
            });
            return;
        }

        // if (personTypeControls.value === 'business' && (bankNameField === undefined || bankNameField == null || bankNameField.length < 3)) {
        //     LogicService.setAlertMessageAndSeverity({
        //         message: 'Trebuie completat numele băncii',
        //         key: 'invalid_cui',
        //         severity: 'error'
        //     });
        //     return;
        // }

        // if (personTypeControls.value === 'business' && (ibanField === undefined || ibanField == null || ibanField.length < 3)) {
        //     LogicService.setAlertMessageAndSeverity({
        //         message: 'Trebuie completat IBAN-ul',
        //         key: 'invalid_cui',
        //         severity: 'error'
        //     });
        //     return;
        // }


        let isInRo = countryField != null && countryField.code == 'RO'

        if ((isInRo && (judet == null || judet.length == 0)) || (!isInRo && (districtField === undefined || districtField == null || districtField.length < 3))) {
            setErrorObj({
                field: 'districtField',
                message: (translate ? 'The county is required' : 'Judetul este necesar')
            })
            return LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The county is required' : 'Judetul este necesar'),
                key: 'invalid_livrare', severity: 'error'
            });
        }

        if ((isInRo && (localitate == null || localitate.length == 0)) || (!isInRo && (cityField === undefined || cityField == null || cityField.length < 3))) {
            setErrorObj({
                field: 'cityField',
                message: (translate ? 'The city is necessary' : 'Orasul este necesar')
            })
            return LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The city is necessary' : 'Orasul este necesar'),
                key: 'invalid_livrare', severity: 'error'
            });
        }

        if (streetField === undefined || streetField == null || streetField.length < 1) {
            setErrorObj({
                field: 'streetField',
                message: (translate ? 'The street is necessary' : 'Strada este necesara')
            })
            return LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'The street is necessary' : 'Strada este necesara'),
                key: 'invalid_street', severity: 'error'
            });
        }

        if (detailsField === undefined || detailsField == null || detailsField.length < 1) {
            setErrorObj({
                field: 'detailsField',
                message: (translate ? 'Details are required' : 'Detaliile sunt necesare')
            })
            return LogicService.setAlertMessageAndSeverity({
                message: (translate ? 'Details are required' : 'Detaliile sunt necesare'),
                key: 'invalid_livrare', severity: 'error'
            });
        }

        let requestBody = {
            orderId: order.id,
            email: emailField,
            phoneNumber: phoneNumberField,
            userType: userTypeField,
            firstName: firstNameField,
            lastName: lastNameField,
            cui: cuiField,
            nrRC: nrRcField,
            bankName: null,
            iban: null,
            country: countryField.label,
            street: streetField,
            district: isInRo ? judet: districtField,
            city: isInRo ? localitate : cityField,
            details: detailsField,
        }

        disableTemporarily();
        CommunicationService.addInvoiceDetails(requestBody)
            .then((response) => {
                LogicService.verifyFiscalInfoAndReturn();
                navigate(urlPreffix + (translate ? '/en/complete-offer' : '/complete-offer'));
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const [anafSearchedCompany, setAnafSearchedCompany] = React.useState(null);
    const [manualInput, setManualInput] = React.useState(false);
    const [firmBorder, setFirmBorder] = React.useState(null);


    const autoSearchCompanyInAnafDB = () => {
        setManualInput(true);
        CommunicationService.getAnafCompany(parseInt(cuiField))
            .then((response) => {
                setAnafSearchedCompany(response.denumire);
                setFirstNameField(response.denumire)
                setNrRcField(response.nrRegCom)
            })
            .catch((error) => {
                setFirmBorder('red');
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ANAF_CUI_NOT_FOUND)
            });
    }

    const back = () => {
        LogicService.resetSession();
        navigate(urlPreffix + '/');
    }

    let judete = Siruta.getJudete();

    const [judet, changeJudet] = useState(isForeignCitizen ? 'BUCURESTI' : null);
    const [localitate, changeLocalitate] = useState(isForeignCitizen ? 'BUCURESTI SECTORUL 1' : null);

    const changeLoc = (loc) => {
        changeLocalitate(loc);
    }

    const modifyJudet = (judet) => {
        changeJudet(judet);
        changeLoc(null);
    }

    useEffect(() => {
        // LogicService.getFeatureSwitchCallback('ANAF_API_INTEGRATION', (data, err) => {
        //     if (err) {
        //         return console.error(err);
        //     }
        //     if (data != null) {
        //         let hideApostila = data.value;
        //         SET_ANAF_API_INTEGRATION(hideApostila);
        //     }
        // });
        console.log('isForeignCitizen', isForeignCitizen);
        if (isForeignCitizen) {
            buttonPress();
        }
    }, [])

    return (
        <FormikProvider value={{}}>
            {/*{*/}
            {/*    shareable ? null : <FormControlLabel*/}
            {/*        label="Datele de facturare sunt aceleași ca în comandă"*/}
            {/*        sx={{marginBottom: "15px"}}*/}
            {/*        control={*/}
            {/*            <Checkbox*/}
            {/*                checked={sameData}*/}
            {/*                onChange={() => {*/}
            {/*                    changeSameData(!sameData);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        }*/}
            {/*    />*/}
            {/*}*/}

            <Form autoComplete="on">
                <Stack spacing={3} sx={{mb: '20px'}}>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                        <ToggleButtonGroup size="large" {...personTypeControls} >
                            {personSelect}
                        </ToggleButtonGroup>
                    </Stack>
                </Stack>

                { userTypeField === undefined || userTypeField === null ? null : <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="email"
                        type="email"
                        label="Email"
                        disabled={(details.username !== undefined && details.username !== null && details.username.length > 1) && sameData}
                        error={errorObj.field === 'emailField'}
                        helperText={errorObj.field === 'emailField' ? errorObj.message : null}
                        onChange={(e) => {
                            setEmailField(e.target.value)
                            setErrorObj({
                                field: '',
                                message: ''
                            })
                        }}
                        value={emailField}
                    />

                    <Stack direction={{xs: 'column', sm: 'row'}} className="phone-number-combined">
                        <CountrySelect defaultValue={countryField} disabled={sameData} label={"Prefix"}
                                       selectOption={(option) => {
                                           setCountryField(option);
                                       }}/>
                        <TextField
                            className={"phone-number-text-field"}
                            fullWidth
                            autoComplete="phoneNumber"
                            label={translate ? "Phone Number" : "Număr de telefon"}
                            disabled={(details.phoneNumber !== undefined && details.phoneNumber !== null && details.phoneNumber.length > 1) && sameData}
                            error={errorObj.field === 'phoneNumberField'}
                            helperText={errorObj.field === 'phoneNumberField' ? errorObj.message : null}
                            onChange={(e) => {
                                setPhoneNumberField(e.target.value)
                                setErrorObj({
                                    field: '',
                                    message: ''
                                })
                            }}
                            value={phoneNumberField}
                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                        />
                    </Stack>

                    {
                        Boolean(personTypeControls.value !== 'person') ? null :
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    fullWidth
                                    label={translate ? "First name" : "Nume"}
                                    disabled={(userDetails.firstName !== undefined && userDetails.firstName !== null && userDetails.firstName.length > 1) && sameData}
                                    error={errorObj.field === 'firstNameField'}
                                    helperText={errorObj.field === 'firstNameField' ? errorObj.message : null}
                                    onChange={(e) => {
                                        setFirstNameField(e.target.value)
                                        setErrorObj({
                                            field: '',
                                            message: ''
                                        })
                                    }}
                                    value={firstNameField}
                                />

                                <TextField
                                    fullWidth
                                    label={translate ? "Last name" : "Prenume"}
                                    disabled={(userDetails.lastName !== undefined && userDetails.lastName !== null && userDetails.lastName.length > 1) && sameData}
                                    error={errorObj.field === 'lastNameField'}
                                    helperText={errorObj.field === 'lastNameField' ? errorObj.message : null}
                                    onChange={(e) => {
                                        setLastNameField(e.target.value)
                                        setErrorObj({
                                            field: '',
                                            message: ''
                                        })
                                    }}
                                    value={lastNameField}
                                />
                            </Stack>
                    }

                    {
                        Boolean(personTypeControls.value == 'business') ?
                            <AnafFiscalSearchFields
                                userDetails={userDetails}
                                errorObj={errorObj}
                                setErrorObj={setErrorObj}
                                sameData={sameData}
                                cuiField={cuiField}
                                setCuiField={setCuiField}
                                firstNameField={firstNameField}
                                setFirstNameField={setFirstNameField}
                                nrRcField={nrRcField}
                                setNrRcField={setNrRcField}
                                anafSearchedCompany={anafSearchedCompany}
                                setAnafSearchedCompany={setAnafSearchedCompany}
                                manualInput={manualInput}
                                setManualInput={setManualInput}
                                firmBorder={firmBorder}
                                setFirmBorder={setFirmBorder}
                            /> : null
                    }
                    {/*{*/}
                    {/*    Boolean(personTypeControls.value == 'business') && !ANAF_API_INTEGRATION ?*/}
                    {/*        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>*/}
                    {/*            <TextField*/}
                    {/*                fullWidth*/}
                    {/*                label="Nume Firma"*/}
                    {/*                // disabled={(userDetails.firstName !== undefined && userDetails.firstName !== null && userDetails.firstName.length > 1) && sameData}*/}
                    {/*                error={errorObj.field === 'firstNameField'}*/}
                    {/*                helperText={errorObj.field === 'firstNameField' ? errorObj.message : null}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    setFirstNameField(e.target.value)*/}
                    {/*                    setErrorObj({*/}
                    {/*                        field: '',*/}
                    {/*                        message: ''*/}
                    {/*                    })*/}
                    {/*                }}*/}
                    {/*                value={firstNameField}*/}
                    {/*            />*/}
                    {/*        </Stack> : null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    Boolean(personTypeControls.value == 'business') && !ANAF_API_INTEGRATION ?*/}
                    {/*        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>*/}
                    {/*            <TextField*/}
                    {/*                fullWidth*/}
                    {/*                label="CUI"*/}
                    {/*                // disabled={(userDetails.cui !== undefined && userDetails.cui !== null && userDetails.cui.length > 1) && sameData}*/}
                    {/*                error={errorObj.field === 'cuiField'}*/}
                    {/*                helperText={errorObj.field === 'cuiField' ? errorObj.message : null}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    setCuiField(e.target.value)*/}
                    {/*                    setErrorObj({*/}
                    {/*                        field: '',*/}
                    {/*                        message: ''*/}
                    {/*                    })*/}
                    {/*                }}*/}
                    {/*                value={cuiField}*/}
                    {/*            />*/}
                    {/*            <TextField*/}
                    {/*                fullWidth*/}
                    {/*                label="Registrul comerțului"*/}
                    {/*                error={errorObj.field === 'nrRcField'}*/}
                    {/*                helperText={errorObj.field === 'nrRcField' ? errorObj.message : null}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    setNrRcField(e.target.value)*/}
                    {/*                    setErrorObj({*/}
                    {/*                        field: '',*/}
                    {/*                        message: ''*/}
                    {/*                    })*/}
                    {/*                }}*/}
                    {/*                value={nrRcField}*/}
                    {/*            />*/}
                    {/*        </Stack> : null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    Boolean(personTypeControls.value !== 'business') ? null :*/}
                    {/*        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>*/}
                    {/*            <TextField*/}
                    {/*                fullWidth*/}
                    {/*                label="Nume bancă"*/}
                    {/*                onChange={(e) => setBankNameField(e.target.value)}*/}
                    {/*                value={bankNameField}*/}
                    {/*            />*/}
                    {/*            <TextField*/}
                    {/*                fullWidth*/}
                    {/*                label="IBAN"*/}
                    {/*                onChange={(e) => setIbanField(e.target.value)}*/}
                    {/*                value={ibanField}*/}
                    {/*            />*/}
                    {/*        </Stack>*/}
                    {/*}*/}
                    <Stack spacing={3}>
                        {
                            countryField != null && countryField.code == 'RO' ? <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>

                                <TextSelectInputPlainString options={judete}
                                                 selectOption={modifyJudet}
                                                 label={translate ? "Choose the county" : "Alege județul"}
                                                 parenthesisFieldName={'code'}
                                                 searchFunction={(judet) => Siruta.getJudetByName(judet)}
                                />
                                <TextSelectInputPlainString options={Siruta.getOraseByJudet(judet)}
                                                            selectOption={(loc) => changeLocalitate(loc)}
                                                            label={translate ? "Choose the city" : "Alege orașul"}
                                                            disabled={judet == null || judet.length == 0}
                                                            searchFunction={(localitate) => Siruta.getOrasByJudetAndName(judet, localitate)}
                                />
                            </Stack> :
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>

                                <TextField
                                    fullWidth
                                    label={translate ? "County" : "Judet"}
                                    // disabled={(address.district !== undefined && address.district !== null && address.district.length > 1) && sameData}
                                    error={errorObj.field === 'districtField'}
                                    helperText={errorObj.field === 'districtField' ? errorObj.message : null}
                                    onChange={(e) => {
                                        setDistrictField(e.target.value)
                                        setErrorObj({
                                            field: '',
                                            message: ''
                                        })
                                    }}
                                    value={districtField}
                                />
                                <TextField
                                    fullWidth
                                    label={translate ? "City" : "Oraș"}
                                    // disabled={(address.city !== undefined && address.city !== null && address.city.length > 1) && sameData}
                                    error={errorObj.field === 'cityField'}
                                    helperText={errorObj.field === 'cityField' ? errorObj.message : null}
                                    onChange={(e) => {
                                        setCityField(e.target.value)
                                        setErrorObj({
                                            field: '',
                                            message: ''
                                        })
                                    }}
                                    value={cityField}
                                />

                            </Stack>
                        }
                        {
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    error={errorObj.field === 'streetField'}
                                    helperText={errorObj.field === 'streetField' ? errorObj.message : null}
                                    onChange={(e) => {
                                        setStreetField(e.target.value)
                                        setErrorObj({
                                            field: '',
                                            message: ''
                                        })
                                    }}
                                    fullWidth
                                    label={translate ? "Street" : "Strada"}
                                    // disabled={(address.street !== undefined && address.street !== null && address.street.length > 1) && sameData}
                                    value={streetField}
                                />
                            </Stack>
                        }
                        {
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    error={errorObj.field === 'detailsField'}
                                    helperText={errorObj.field === 'detailsField' ? errorObj.message : null}
                                    onChange={(e) => {
                                        setDetailsField(e.target.value)
                                        setErrorObj({
                                            field: '',
                                            message: ''
                                        })
                                    }}
                                    fullWidth
                                    label={translate ? "Details" : "Detalii (scara, etaj, număr)"}
                                    // disabled={(address.details !== undefined && address.details !== null && address.details.length > 1) && sameData}
                                    value={detailsField}
                                />
                            </Stack>
                        }
                    </Stack>

                </Stack>}

                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                    <FormControlLabel
                        sx={{margin: "10px 0px 0px 0px"}}
                        control={<Checkbox name="lgpd_agreement"
                                           onChange={(e) => {
                                               setTacCheckBoxEnabled(!tacCheckBoxEnabled);
                                           }}/>}
                        label={
                            <p>
                                {translate ? "I accept and agree with all of them" : "Accept și sunt de acord cu toți"}
                                <Link sx={{margin: '0 5px'}}
                                      href={"https://caziere.ro/terms-and-conditions"}
                                      target={"_blank"}
                                >
                                    {translate ? "terms" : "termenii"}
                                </Link>
                                {translate ? "and the conditions of the caziere.ro website" : "și condițiile site-ului caziere.ro"}
                            </p>
                        }
                    />
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}} spacing={2} alignSelf={"center"}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'warning'}
                        onClick={back}
                    >
                        {translate ? "Home" : "Acasă"}
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={buttonPress}
                        disabled={tacCheckBoxEnabled || (!buttonEnabled || Boolean(personTypeControls.value === ''))}
                    >

                        {translate ? "Finish" : "Finalizare"}
                    </Button>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
