import {sentenceCase} from 'change-case';
import * as React from 'react';
import {Component} from 'react';
// material
import {
    Box,
    Button,
    Card,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    ToggleButton,
    Typography
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead} from '../components/_dashboard/user';
//
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import {STATUSES} from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import OrderMoreMenu from "../components/_dashboard/user/OrderMoreMenu";
import AddPaymentLinkModal from "../components/_dashboard/user/AddPaymentLinkModal";
import invoiceIcon from '@iconify/icons-eva/copy-fill';
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router-dom";
import {getCommunicationLogs} from "../services/CommunicationService";

function md5(inputString) {
    var hc="0123456789abcdef";
    function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
    function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
    function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
    function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
    function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
    function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
    function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
    function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
    function sb(x) {
        var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
        for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
        blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
    }
    var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
    for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
        a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
        b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
        c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
        d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
        a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
        b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
        c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
        d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
        a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
        b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
        c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
        d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
        a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
        b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
        c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
        d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
        a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
        b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
        c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
        d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
        a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
        b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
    }
    return rh(a)+rh(b)+rh(c)+rh(d);
}

const TABLE_HEAD = [
    {id: 'status', label: 'Status', alignRight: false},
    {id: 'price', label: 'Pret', alignRight: false},
    {id: 'createdAt', label: 'Creat', alignRight: false},
    {id: 'details', label: 'Link', alignRight: false},
    {id: ''}
];

export default class PaymentLinks extends Component {

    constructor() {
        super()
        this.state = {
            detailsModalIsOpen: false,
            addPaymentLinkModalOpen: false,
            tableHead: TABLE_HEAD,
            isLawyer: true,
            orders: [],
            statuses: [],
            transactions: [
                {
                    label: 'Verified',
                    value: true
                },
                {
                    label: 'Not verified',
                    value: false
                }
            ],
            page: 0,
            order: 'desc',
            selected: [],
            orderBy: 'createdAt',
            filterBy: null,
            filterName: null,
            filterLawyer: null,
            filterStatus: null,
            filterTransaction: null,
            rowsPerPage: 10,
            totalItems: 0,
            totalPages: 0
        }
    }

    setOrders = (val) => {
        this.setState({orders: val})
    }

    setDetailsModalIsOpen = (val) => {
        this.setState({detailsModalIsOpen: val})
    }

    setPage = (val) => {
        this.setState({page: val}, () => this.getOrders(true))
    }

    setOrder = (val) => {
        this.setState({order: val}, () => this.getOrders(true))
        // this.setState({order: val})
    }

    setSelected = (val) => {
        this.setState({selected: val})
    }

    setOrderBy = (val) => {
        this.setState({orderBy: val}, () => {
            // this.getOrders()
        })
    }

    setRowsPerPage = (val) => {
        this.setState({rowsPerPage: val}, () => this.getOrders(true))
    }

    mapOrder = (order) => {
        let user = order.user;


        let orderStatus = order.status;
        let color = (STATUSES[orderStatus.toUpperCase()] || {}).color;
        let orderMapped = {
            id: order.id,
            avatarUrl: order.avatarUrl,
            userType: order.isFor === 'PERSON' ? 'PF' : 'SRL',
            transactionVerified: order.transactionVerified,
            awbGenerated: order.awbGenerated,
            translation: order.translation,
            translationLanguage: order.translationLanguage,
            regime: order.regime,
            legalized: order.legalized,
            apostila: order.apostila,
            address: order.address,
            delivery: order.delivery,
            status: orderStatus,
            createdAt: new Date(order.createdAt).toLocaleString(),
            price: order.price?.toString(),
            email: user.username,
            phoneNumber: user.phoneNumber,
            asigned: order.type,
            reason: order.reason,
            details: order.legalized + ' - ' + order.translation,
            color: color,
            active: order.active,
            documents: order.documents,
            browserCountry: order.browserCountry,
            browserIP: order.browserIP,
            problems: order.problems,
            fiscalDone: order.fiscalDone,
            shareCode: order.shareCode
        };
        if (!this.state.isLawyer) {
            orderMapped['access'] = order.access.map(us => this.getUserWithNameFrom(us));
        }
        return orderMapped
    }

    componentDidMount() {
        let Auth = CommunicationService.getUser();
        let userType = CommunicationService.getUserType();
        let isModeratorOrGreater = Auth !== null && (userType === 'ROLE_ADMIN' || userType === 'ROLE_MODERATOR')
        this.setState({
            isLawyer: !isModeratorOrGreater
        }, () => {
            CommunicationService.getAllStatuses()
                .then((response) => {
                    this.setState({
                        statuses: response
                    })
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
            this.getOrders();
        })
    }

    getUserWithNameFrom(user) {
        let userDetails = user.userDetails || null;
        let name = userDetails !== null ? userDetails.firstName + ' ' + userDetails.lastName : user.username;
        return {
            ...user,
            name: name
        }
    }

    getOrders(force = false) {
        if (force || this.state.orders.length === 0) {
            CommunicationService.getOrders(this.state.page, this.state.rowsPerPage, this.state.orderBy,
                this.state.order, null, null, true)
                .then((response) => {
                    this.setState({
                        totalItems: response.totalItems,
                        totalPages: response.totalPages
                    })
                    this.setOrders(response.orders.map(order => this.mapOrder(order)))
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
        }
    }

    getReason(text, value, key, icon, problems) {
        let sx = {padding: "3px 5px !important"};
        if (problems === true) {
            sx.background = "red";
            sx.color = "white";
        }
        return <ToggleButton value={value} key={key} sx={sx}>
            <Typography variant={'body2'} sx={{margin: "0 !important"}} gutterBottom>{text}</Typography>
        </ToggleButton>;
    }

    addPaymentLink (price) {
        CommunicationService.paymentLinkCreate(price)
            .then((response) => {
                location.reload();
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.PAYMENT_LINK_CREATED);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    getURlStyled(text, active) {
        let boxClick = (e) => {
            navigator.permissions
                .query({ name: "clipboard-write" })
                .then((result) => {
                    if (result.state === "granted" || result.state === "prompt") {
                        navigator.clipboard.writeText(text).then(
                            () => {
                                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.COPIED_TO_CLIPBOARD);
                            },
                            () => {
                                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.CANNOT_COPY_TO_CLIPBOARD);
                            }
                        );
                    }
                });
        }
        let sx = {border: "2px solid green", padding: "6px", borderRadius: "5px"};
        if (active === false) {
            sx.border = "2px solid red";
            sx.background = "rgba(255, 99, 71, 0.6)";
        }
        return <Box onClick={boxClick} display={"flex"} justifyContent={"space-between"}>
            <Icon class="copyIcon" style={{marginTop: '6px', marginRight: "10px"}} icon={invoiceIcon} width={24} height={24}/>
            <Box sx={sx}>{text}</Box>
        </Box>;
    }

    render() {
        const ORDERLIST = this.state.orders;
        const page = this.state.page;
        const order = this.state.order;
        const selected = this.state.selected;
        const orderBy = this.state.orderBy;
        const filterName = this.state.filterName;
        const rowsPerPage = this.state.rowsPerPage;

        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === 'asc';
            this.setOrder(isAsc ? 'desc' : 'asc');
            this.setOrderBy(property);
        };

        const handleSelectAllClick = (event) => {
            if (event.target.checked) {
                const newSelecteds = ORDERLIST.map((n) => n.name);
                this.setSelected(newSelecteds);
                return;
            }
            this.setSelected([]);
        };

        const handleChangePage = (event, newPage) => {
            this.setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            this.setRowsPerPage(parseInt(event.target.value, 10));
            this.setPage(0);
        };


        const filteredUsers = ORDERLIST;
        const isUserNotFound = filteredUsers.length === 0;





        return (
            <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
                <Container maxWidth={"1600px"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Payment Links
                        </Typography>
                    </Stack>

                    <Card sx={{padding: "10px"}}>
                        <Stack direction={{xs: 'column', sm: 'row'}} alignItems="center">
                            <Button variant="contained" color={'warning'} sx={{margin: "25px"}}
                                    onClick={() => this.setState({addPaymentLinkModalOpen: true})}>
                                {"Create"}
                            </Button>
                        </Stack>
                        <AddPaymentLinkModal open={this.state.addPaymentLinkModalOpen}
                                          handleConfirm={(text) => {
                                              this.addPaymentLink(text);
                                              this.setState({addPaymentLinkModalOpen: false})
                                          }}
                                          handleClose={() => this.setState({addPaymentLinkModalOpen: false})}
                        />
                        <Scrollbar>

                            <TableContainer sx={{minWidth: 800}}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={this.state.tableHead}
                                        rowCount={this.state.totalItems}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredUsers
                                            .map((row) => {
                                                const {
                                                    id,
                                                    price,
                                                    status,
                                                    createdAt,
                                                    transactionVerified,
                                                    color,
                                                    active,
                                                    shareCode,
                                                    fiscalDone,
                                                } = row;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                    >
                                                        <TableCell padding="checkbox">

                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Label
                                                                variant="ghost"
                                                                color={color}
                                                            >
                                                                {sentenceCase(status)}
                                                            </Label>
                                                        </TableCell>

                                                        {
                                                            this.state.isLawyer ? null :
                                                                <TableCell align="left">
                                                                    <Label
                                                                        variant="ghost"
                                                                        color={transactionVerified ? 'success' : 'error'}
                                                                    >
                                                                        {sentenceCase(price)}
                                                                    </Label>
                                                                </TableCell>

                                                        }
                                                        <TableCell align="left">{createdAt}</TableCell>
                                                        <TableCell align="left" >
                                                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}
                                                                   alignSelf={"center"}>
                                                                {
                                                                    shareCode === undefined || shareCode === null ? null :
                                                                        this.getURlStyled(window.location.origin + "/complete-order/" + id + "/" + md5(shareCode).toUpperCase(), active)
                                                                }
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <OrderMoreMenu row={row} isLawyer={this.state.isLawyer} invoiceOnly={true}/>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}

                                    </TableBody>
                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                    <SearchNotFound searchQuery={filterName}/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.totalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Container>
            </Page>
        );
    }
}
