// material
import {Box, Container, Grid} from '@mui/material';
// components
import Page from '../components/Page';
import * as React from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import * as CommunicationService from "../services/CommunicationService";
import {Helmet} from "react-helmet-async";
import {useEffect} from "react";
import * as LogicService from "../services/LogicService";
import OrderDetailsTable from "./OrderDetailsTable";
import * as StorageService from "../services/StorageService";


// ----------------------------------------------------------------------


export default function PaymentRedirect() {
    let {orderId, orderCode} = useParams();

    let [orderObjectFromServer, setOrderObjectFromServer] = React.useState({
        expertVerification: false,
        phoneNumber: "",
        price: "",
        regime: "",
        userFirstName: "",
        userLastName: "",
        username: "",
    });
    let [confirmedAlready, setConfirmedAlready] = React.useState(false);
    let [text, setText] = React.useState("Se confirmă plata, vă rugăm așteptați!");
    const navigate = useNavigate();

    useEffect(() => {
        let order = LogicService.getOrder();
        if (order != undefined && order.details != undefined) {
            setOrderObjectFromServer({
                expertVerification: (order.details?.expertVerification === true ? 'Da' : 'Nu'),
                phoneNumber: order.details?.phoneNumber,
                price: order.price,
                regime: order.details?.regime?.toUpperCase(),
                userFirstName: order.details?.userDetails?.firstName,
                userLastName: order.details?.userDetails?.lastName,
                username: order.details?.username,
            })
        }


    }, [])


    function confirmPayment(orderId, orderCode) {
        CommunicationService.confirmPayment(orderId, orderCode)
            .then((response) => {
                setOrderObjectFromServer({
                    expertVerification:  (response.expertVerification === true ? 'Da' : 'Nu'),
                    phoneNumber: response.phoneNumber,
                    price: response.price,
                    regime: response.regime,
                    userFirstName: response.userFirstName,
                    userLastName: response.userLastName,
                    username: response.username,
                })

                setTimeout(function () {
                    StorageService.setOrder(null);
                    navigate('/')
                }, 10000);

                setText(response.message);
                setConfirmedAlready(true);
            })
            .catch((error) => {
                setTimeout(function () {
                    StorageService.setOrder(null);
                    navigate('/')
                }, 5000);
                setText(JSON.parse(error.message).message);
            });
    }

    if (!confirmedAlready) {
        window.gtag('event', 'conversion', {'send_to': 'AW-840657989/R56dCIyWutgZEMXY7ZAD'});
        confirmPayment(orderId, orderCode);
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Helmet>
                <meta name="googlebot" content="noindex"/>
                <meta name="robots" content="noindex"/>

                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>

            </Helmet>
            <Container maxWidth="xl">
                <Grid container spacing={5} align={"center"}>


                    <Grid item xs={12} md={12} lg={12}>
                        <Typography component="div" variant="h3" style={{marginTop: '2%'}}>
                            {text}
                        </Typography>

                        <Box sx={{minWidth: 240,
                            background: 'white',
                            maxWidth: {xs: '95%', sm: '60%'},
                            border: '1px solid black', borderRadius: '10px', padding: '15px', marginTop: '2%'}}>

                            <OrderDetailsTable orderObjectFromServer={orderObjectFromServer}/>
                        </Box>
                        <Typography component="div" variant="h3" style={{marginTop: '2%'}}>
                            Veți fi redirectat pe pagina principală în 5 secunde ...
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Page>
    );
}
