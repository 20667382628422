const TERMS_AND_CONDITIONS = {
    PAGE_TITLE: "Termeni Si Conditii",
    DESCRIPTION: [
        "Folosirea siteului caziere.ro presupune acceptarea termenilor si conditiilor mentionate mai jos. In cazul in care aveti intrebari sau nelamuriri, va rugam sa ne contactati pe adresa de email contact@caziere.ro",
        "Este INTERZISA solicitarea de documente in numele altor persoane fara cunostinta si acordul acestora pe caziere.ro, acest fapt putand fi pedepsit cu inchisoare de la 1 la 5 ani, conform Art 325 din Legea 286/2009 privind falsul informatic."
    ],
    ROWS: [
        {
            TITLE: "Descriere",
            LINES: [
                "caziere.ro este un site care va pune la dispozitie posibilitatea de a obtine diverse documente precum certificatul de cazier judiciar prin oferirea unor servicii pentru pregatirea documentelor alaturi de parteneriate cu avocati/juristi specilizati. Fiecare operatiune necesita in principiu aceeasi pasi, insa pentru a afla cum functioneaza in mod concret, va trebui sa verificati paginile sitului nostru images destinate fiecarui serviciu oferit."
            ]
        },
        {
            TITLE: "Cum comand?",
            LINES: [
                "Daca doriti sa comandati un serviciu de pe site-ul caziere.ro trebuie sa completati informatiile solicitate prin formularul de comanda. Aceste informatii sunt necesare pentru procesarea corecta si eficienta a comenzii plasate de catre dumneavoastra. Informatiile personale nu sunt transferate catre alte terte parti. Dupa plasarea comenzii se va emite o confirmare in acest sens pe email-ul dvs., situatie ce atesta fermitatea cererii dvs. si implicit acceptarea ofertei noastre de servicii. Totodata, se va emite si o factura, precum si un contract ce este necesar sa-l semnati pentru procesarea documentelor dvs. in fata Inspectoratului de Politie Judeteana denumit mai departe IPJ sau birouri de traduceri/apostile si firme de curierat si care presupune acordarea dreptului de a incheia in numele dvs., fara niciun cost aferent, cu un partener ce activeaza in domeniul juridic, un contract de reprezentare juridica a dvs. pentru ridicarea si trimiterea documentelor. Documentele mentionate anterior pot fi expediate impreuna sau separat, online sau fizic."
            ]
        },
        {
            TITLE: "Cum platesc?",
            LINES: [
                "Toate preturile afisate pe site includ tva. Plata se poate realiza ramburs, prin virament bancar sau online, in functie de sistemul existent la momentul comenzii efectuate de catre dvs. Datele completate de dumneavoastra sunt in siguranta, intrucat caziere.ro nu are acces la datele dumneavoastra bancare."
            ]
        },
        {
            TITLE: "Preturi si taxe",
            LINES: [
                "Preturile sunt stabilite atunci cand produsele sunt afisate pe site, sunt exprimate in RON si includ TVA. Pretul afisat se poate modifica fara o notificarea prealabila pe siteul caziere.ro",
                "Pretul obtinerii unui cazier judiciar mergand la ghiseu fizic la Inspectoratul De Politie Judeteana este 0 lei. caziere.ro este insa un serviciu platit."
            ]
        },
        {
            TITLE: "Anulare",
            LINES: [
                "Orice comandă plătită nu poate fi anulată. Acest lucru se menționează și în contractul de servicii semnat de client înainte de efectuarea plății."
            ]
        },
        {
            TITLE: "Competenta",
            LINES: [
                "Contractul dintre noi si dvs. va fi guvernat de legea romana si se interpreteaza in conformitate cu reglementarile cu privire la aceasta. Orice neintelegeri se solutioneaza pe cale amiabila. In cazul in care neintelegerile nu pot fi rezolvate pe cale amiabila, ele vor fi supuse la Curtea de Arbitraj Comercial International de Camera de Comert si Industrie din Romania, in conformitate cu reglementarea acestora."
            ]
        },
        {
            TITLE: "Drepturi de autor",
            LINES: [
                "Design-ul, textele, grafica, structura si toate informatiile de pe site-ul caziere.ro sunt proprietatea exclusiva a SC RIGESERA SRL cu sediul in Bdul Socola nr 3 Bloc D2 Scara A Ap 20, Iasi si avand CIF 51014507 si J2024048041009. Orice incercare de a copia, reproduce, republica, transfera date, afisa, distribui, vinde sau modifica din continutul, datele, informatiile si materialele gasite pe site-ul nostru este strict interzisa fara acordul scris al reprezentantului SC RIGESERA SRL."
            ]
        },
        {
            TITLE: "Reclamatii",
            LINES: [
                "Pentru orice tip de reclamatii, va rugam sa ne contactati la adresa de email: contact@caziere.ro. Va asiguram ca echipa noastra va face tot posibilul sa rezolve in cel mai scurt timp posibil reclamatiile dumneavoastra."
            ]
        },
        {
            TITLE: "Protectia datelor cu caracter personal",
            LINES: [
                "Conform cerintelor Legii nr. 677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, modificata si completata si ale Legii nr. 506/2004 privind prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor electronice, SC RIGESERA SRL are obligatia de a administra in conditii de siguranta si numai pentru scopurile specificate, datele personale pe care ni le furnizati despre dumneavoastra, un membru al familiei dumneavoastra ori o alta persoana. Scopul colectarii datelor este procesarea comenzilor si dorinta de a va aduce la cunostiinta modificarile din cadrul serviciilor noastre si eventualele servicii noi pe care le oferim noi sau partenerii nostrii. Sunteti obligat(a) sa furnizati datele, acestea fiind necesare pentru realizarea documentelor de facturare. Refuzul dvs. determina neprocesarea/ anularea comenzii. Informatiile inregistrate sunt destinate utilizarii de catre operator si sunt comunicate numai urmatorilor destinatari: caziere.ro, SC RIGESERA SRL. Conform Legii nr. 677/2001, beneficiati de dreptul de acces, de interventie asupra datelor, dreptul de a nu fi supus unei decizii individuale si dreptul de a va adresa justitiei. Totodata, aveti dreptul sa va opuneti prelucrarii datelor personale care va privesc si sa solicitati stergerea datelor. Pentru exercitarea acestor drepturi, va puteti adresa cu o cerere scrisa, datata si semnata la contact@caziere.ro. Daca unele dintre datele despre dumneavoastra sunt incorecte, va rugam sa ne informati cat mai curand posibil la adresa de email mentionata anterior.",
                "Datele personale pe care le prelucram sunt, obligatoriu, doar datele dvs. de identificare si nu ale altor persoane, atunci cand deveniti clientul nostru, sau cand folositi serviciile noastre.",
                "Prelucram datele personale pentru a va furniza servicii sau consultanta cu privire la certificatul de cazier judiciar. Prelucrarea datelor personale se intemeiaza pe executarea contractelor, pe nevoia de a respecta o obligatie legala, pe interesul nostru legitim sau un interes public major sau, dupa caz, pe consimtamantul dvs. daca v-ati manifestat optiunea in acest sens.",
                "Pentru a va oferi servicii cat mai competitive ,pentru indeplinirea obligatiilor legale ce ne revin sau in alte scopuri legitime este posibil sa va transmitem datele personale catre autoritati publice, organe judiciare, birouri notariale, consultanti externi, alte institutii financiar-bancare, imputerniciti catre care am externalizat furnizarea anumitor servicii si alte categorii de destinatari, din Romania sau din afara ei sau a Uniunii Europene/Spatiului Economic European, asigurandu-ne insa intotdeauna ca instituim garantii adecvate pentru protejarea datelor.",
                "Datele personale sunt prelucrate pe tot parcursul contractului si, dupa finalizarea acesteia, cel putin pe perioada impusa de prevederile legale aplicabile in domeniu, inclusiv, dar fara limitare, la dispozitiile privind arhivarea.",
                "Pe langa drepturile existente, de la data aplicarii Regulamentului beneficieati de drepturi aditionale. Iata lista completa a drepturilor:" +
                "- Dreptul la informare – puteti solicita informatii privind activitatile de prelucrare a datelor personale;",
                "- Dreptul la rectificare – puteti rectifica datele personale inexacte sau le puteti completa;",
                "- Dreptul la stergerea datelor („dreptul de a fi uitat”) – puteti obtine stergerea datelor, in cazul in care prelucrarea acestora nu a fost legala sau in alte cazuri prevazute de lege;",
                "- Dreptul la restrictionarea prelucrarii – puteti solicita restrictionarea prelucrarii in cazul in care contestati exactitatea datelor, precum si in alte cazuri prevazute de lege;",
                "- Dreptul de opozitie – puteti sa va opuneti, in images, prelucrarilor de date care se intemeiaza pe interesul nostru legitim;",
                "- Dreptul la portabilitatea datelor – puteti primi, in anumite conditii, datele personale pe care ni le-ati furnizat, intr-un format care poate fi citit automat sau puteti solicita ca respectivele date sa fie transmise altei persoane juridice;",
                "- Dreptul de a depune plangere – puteti depune plangere fata de modalitatea de prelucrare a datelor personale la Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal;",
                "- Dreptul de retragere a consimtamantului – in cazurile in care prelucrarea se intemeiaza pe consimtamantul dvs., vi-l puteti retrage oricand. Retragerea consimtamantului va avea efecte doar pentru viitor, prelucrarea efectuata anterior retragerii ramanand in continuare valabila;",
                "Va puteti exercita aceste drepturi, fie individual, fie cumulat, prin transmiterea unei solicitari prin E-mail, la: contact@caziere.ro."
            ]
        },
        {
            TITLE: "Alte mentiuni",
            LINES: [
                "Prezentele conditii se completeaza cu legislatia in vigoare. De asemenea, termenii si conditiile prezentate pe acest site pot fi modificate fara a exista o notificarea prealabila."
            ]
        }
    ]
}

const PRIVACY_POLICY = {
    PAGE_TITLE: "Politica De Confidentialitate",
    DESCRIPTION: [],
    ROWS: [
        {
            TITLE: "1. Informatii generale",
            LINES: [
                "Confidentialitatea datelor dumneavoastra cu caracter personal reprezinta una dintre preocuparile principale ale RIGESERA SRL, cu sediul in Bulevardul Socola nr 3 bloc D2 scara A ap 20 700186 Iasi, Romania, in calitate de operator de date.",
                "Acest document are rolul de a va informa cu privire la prelucrarea datelor dumneavoastra cu caracter personal, in contextul utilizarii paginii de internet caziere.ro. („Site-ul”)  ",
            ]
        },
        {
            TITLE: "2. Categoriile de date cu caracter personal prelucrate",
            LINES: [
                "2.1 Daca sunteti client al Site-ului, RIGESERA S.R.L. va prelucra datele dumneavoastra cu caracter personal, cum ar fi nume si prenume, telefon, adresa de e-mail, adresa de facturare, adresa de livrare, date referitoare la modul in care utilizati Site-ul, de exemplu: comportamentul/preferintele/obisnuintele dumneavoastra in cadrul RIGESERA S.R.L., precum si orice alte categorii de date pe care le furnizati in mod direct in contextul crearii contului de utilizator, in contextul plasarii unei comenzi prin intermediul site-ului sau in orice alt mod care rezulta din utilizarea Site-ului.",
                "2.2 Daca sunteti vizitator al Site-ului, RIGESERA S.R.L. va prelucra datele dumneavoastra cu caracter personal pe care le furnizati in mod direct in contextul utilizarii Site-ului, cum ar fi datele pe care le furnizati in cadrul sectiunii de contact, in masura in care ne contactati in acest fel."
            ]
        },
        {
            TITLE: "3. Scopurile si temeiurile prelucrarii",
            LINES: [
                "3.1. Daca sunteti client al Site-ului, RIGESERA S.R.L. prelucreaza datele dumneavoastra cu caracter personal astfel:",
                "– pentru desfasurarea relatiei contractuale dintre dumneavoastra si RIGESERA S.R.L., respectiv pentru preluarea, validarea, expedierea si facturarea comenzii plasate pe Site, informarea dumneavoastra asupra starii comenzii, organizarea returului de produse comandate etc.",
                "Temei: Prelucrarea datelor dumneavoastra in acest scop are la baza contractul incheiat intre dumneavoastra si RIGESERA S.R.L., definit in cuprinsul Termenelor si Conditiilor. Furnizarea datelor dumneavoastra cu caracter personal este necesara pentru executareaa acestui contract. Refuzul furnizarii datelor poate avea drept consecinta imposibilitatea derularii raporturilor contractuale dintre dumneavoastra si RIGESERA S.R.L.",
                "– pentru indeplinirea obligatiilor legale care incumba RIGESERA S.R.L. in contextul serviciilor prestate prin intermediul Site-ului, inclusiv a obligatiilor in materie fiscala, precum si in materie de arhivare.",
                "Temei: Prelucrarea datelor dumneavoastra pentru acest scop este necesara in baza unor obligatii legale. Furnizarea datelor dumneavoastra in acest scop este necesara. Refuzul furnizarii datelor poate avea drept consecinta imposibilitatea RIGESERA S.R.L. de a respecta obligatiile legale care ii revin si deci in imposibilitatea de a va oferi serviciile prin intermediul Site-ului.",
                "– pentru activitati de marketing, respectiv pentru transmiterea, prin intermediul mijloacelor de comunicare la distanta (e-mail, sms, telefon, chat) de comunicari comerciale privind produsele si serviciile oferite de RIGESERA S.R.L., prin intermediul Site-ului.",
                "Temei: Prelucrarea datelor dumneavoastra pentru acest scop are la baza consimtamantul dumneavoastra, daca alegeti sa-l furnizati.",
                "Furnizarea datelor dumneavoastra in acest scop este voluntara. Refuzul furnizarii consimtamantului pentru prelucrarea datelor dumneavoastra in acest scop nu va avea urmari negative pentru dumneavoastra. Daca doriti ca datele dumneavoastra sa nu fie folosite in scop de marketing va rugam sa ne trimiti un email la adresa: contact@caziere.ro",
                "- in scopul efectuarii diverselor analize, raportari privind modul de functionare a Site-ului, realizarea de profiluri de preferinte de consum, in principal, in vederea imbunatatiri experientei oferite pe Site.",
                "Temei: Prelucrarea datelor dumneavoastra pentru acest scop are la baza interesul legitim al RIGESERA S.R.L. de a imbunatatii permanent experienta clientilor pe Site. Furnizarea datelor dumneavoastra in acest scop este voluntara. Refuzul furnizarii datelor pentru acest scop nu va avea urmari negative pentru dumneavoastra.",
            ]
        },
        {
            TITLE: "Durata pentru care va prelucram datele",
            LINES: [
                "RIGESERA S.R.L. va prelucra datele dumneavoastra cu caracter personal timp de 30 zile. Dupa aceste 30 zile, datele cu caracter personal sunt sterse, iar documente precum cazierul judiciar sunt distruse, exceptie facand datele primite pentru verificarea identitatii. Aceste date pentru verificarea identitatii, trimise de client, sunt arhivate dupa 30 zile si se pastreaza pentru un timp nedefinit, in eventualitatea solicitarii lor de catre autoritatile competente.",
                "In cazul in care sunteti client, vom prelucra datele dumneavoastra pe intreaga durata a raporturilor contractuale si ulterior conform obligatiilor legale care revin in sarcina RIGESERA S.R.L. (de ex., in cazul documentelor justificative financiar-contabile pentru care termenul de pastrare prevazut de lege este de 10 ani de la data incheierii exercitiului financiar in cursul caruia au fost intocmite).",
                "In situatia in care sunteti client si va exercitati optiunea de stergere a datelor personale, se poate exercita acest drept prin trimiterea unui email catre contact@caziere.ro. In acest sens, daca alegeti sa va stergeti datele personale, nu va vom mai trimite e-mailuri si/sau sms-uri, sau orice alt fel de comunicat catre dumneavoastra.. In cazul in care solicitati stergerea datelor, insa exista cel putin o comanda active din partea dvs, cererea de stergere a datelor va putea fi inregistrata numai dupa livrarea produselor si finalizarea ultimei comenzi active.",
                "Daca va retrageti consimtamantul pentru prelucrarea datelor in scop de marketing, RIGESERA S.R.L. va inceta prelucrarea datelor dumneavoastra cu caracter personal in acest scop, fara insa a afecta prelucrarile desfasurate de RIGESERA S.R.L. pe baza consimtamantului exprimat de catre dumneavoastra inainte de retragerea acestuia."
            ]
        },
        {
            TITLE: "Dezvaluirea datelor cu caracter personal",
            LINES: [
                "Pentru indeplinirea scopurilor de prelucrare, RIGESERA S.R.L. poate dezvalui datele dumneavoastra catre parteneri, catre terte persoane sau entitati care sprijina RIGESERA S.R.L. in desfasurarea activitatii prin intermediul Site-ului (de exemplu avocati, firme de curierat, furnizori de servicii IT), ori catre autoritatile publice centrale/locale, in urmatoarele cazuri exemplificativ enumerate:",
                "– pentru imputernicire in vederea eliberarii de documente solicitate de client;",
                "– pentru administrarea Site-ului;",
                "– in situatiile in care aceasta comunicare ar fi necesara pentru atribuirea de premii sau alte facilitati persoanelor vizate, obtinute ca urmare a participarii lor la diverse campanii promotionale organizate de catre RIGESERA S.R.L. prin intermediul Site-ului;",
                "– pentru mentinerea, personalizarea si imbunatatirea Site-ului si a serviciilor derulate prin intermediul lui;",
                "–  pentru efectuarea analizei datelor, testarea si cercetarea, monitorizarea tendintelor de utilizare si activitate, dezvoltarea caracteristicilor de siguranta si autentificarea utilizatorilor;",
                "–  pentru transmiterea de comunicari comerciale de marketing, in conditiile si limitele prevazute de lege;",
                "–  atunci cand dezvaluirea datelor cu caracter personal este prevazuta de lege etc.",
            ]
        },
        {
            TITLE: "Transferul datelor cu caracter personal",
            LINES: [
                "Datele cu caracter personal furnizate catre RIGESERA S.R.L. pot fi transferate in afara Romaniei, in situatii cum ar fi folosirea unor firme de curierat international.",
                "Drepturile de care beneficiati",
                "In conditiile prevazute de legislatia in materia prelucrarii datelor cu caracter personal, in calitate de persoane vizate, beneficiati de urmatoarele drepturi:",
                "– dreptul la informare, respectiv dreptul de a primi detalii privind activitatile de prelucrare efectuate de catre RIGESERA S.R.L., conform celor descrise in prezentul document;",
                "– dreptul de acces la date, respectiv dreptul de a obtine confirmarea din partea RIGESERA S.R.L. cu privire la prelucrarea datelor cu caracter personal, precum si detalii privind activitatile de prelucrare precum modalitatea in care sunt prelucrate datele, scopul in care se face prelucrarea, destinatarii sau categoriile de destinatari ai datelor, etc;",
                "– dreptul la rectificare, respectiv dreptul de a obtine corectarea, fara intarzieri justificate, de catre RIGESERA S.R.L. a datelor cu caracter personal inexacte/ nejustificate, precum si completarea datelor incomplete; Rectificarea/ completarea va fi comunicata fiecarui destinatar la care au fost transmise datele, cu exceptia cazului in care acest lucru se dovedeste imposibil sau presupune eforturi disproportionate.",
                "– dreptul la stergerea datelor, fara intarzieri nejustificate, („dreptul de a fi uitat”), in cazul in care se aplica unul dintre urmatoarele motive:",
                "       –  acestea nu mai sunt necesare pentru indeplinirea scopurilor pentru care au fost colectate sau prelucrate;",
                "       –  in cazul in care este retras consimtamantul si nu exista niciun alt temei juridic pentru prelucrare;",
                "       –  in cazul in care persoana vizata se opune prelucrarii si nu exista motive legitime care sa prevaleze;",
                "       –  in cazul in care datele cu caracter personal au fost prelucrate ilegal;",
                "       –  in cazul in care datele cu caracter personal trebuie sterse pentru respectarea unei obligatii legale;",
                "       –  datele cu caracter personal au fost colectate in legatura cu oferirea de servicii ale societatii informationale conform dreptului Uniunii sau al dreptului intern sub incidenta caruia se afla operatorul.",
                "Este posibil ca, in urma solicitarii de stergere a datelor, RIGESERA S.R.L. sa anonimizeze aceste date (lipsindu-le astfel de caracterul personal) si sa continue in aceste conditii prelucrarea pentru scopuri statistice;",
                "– dreptul la restrictionarea prelucrarii in masura in care:",
                "1. persoana contesta exactitatea datelor, pe o perioada care ne permite verificarea corectitudinii datelor;",
                "2. prelucrarea este ilegala, iar persoana vizata se opune stergerii datelor cu caracter personal, solicitand in schimb restrictionarea utilizarii lor;",
                "3. operatorul nu mai are nevoie de datele cu caracter personal in scopul prelucrarii, dar persoana vizata i le solicita pentru constatarea, exercitarea sau apararea unui drept in instanta; sau",
                "4. persoana vizata s-a opus prelucrarii (altele decat cele de marketing direct), pentru intervalul de timp in care se verifica daca drepturile legitime ale operatorului prevaleaza asupra celor ale persoanei vizate.",
                "- dreptul la portabilitatea datelor, respectiv (i) dreptul de a primi datele cu caracter personal intr-o modalitate structurata, folosita in mod obisnuit si intr-un format usor de citit, precum si (ii) dreptul ca aceste date sa fie transmise de catre RIGESERA S.R.L. catre alt operator de date, in masura in care sunt indeplinite conditiile prevazute de lege;",
                "- dreptul la opozitie – in ceea ce priveste activitatile de prelucrare se poate exercita prin transmiterea unei solicitari conform celor indicate mai jos;",
                "- in orice moment, din motive legate de situatia particulara in care se afla persoana vizata, ca datele care o vizeaza sa fie prelucrate in temeiul interesului legitim al RIGESERA S.R.L. sau in temeiul interesului public, cu exceptia cazurilor in care RIGESERA S.R.L. poate demonstra ca are motive legitime si imperioase care justifica prelucarea si care prevaleaza asupra intereselor, drepturilor si libertatilor persoanelor vizate sau ca scopul este constatarea, exercitarea sau apararea unui drept in instanta;",
                "- in orice moment, in mod gratuit si fara nici o justificare, ca datele care o vizeaza sa fie prelucrate in scop de marketing direct.",
                "- dreptul de a nu fi supus unei decizii individuale automate, respectiv dreptul de a nu fi subiectul unei decizii luate numai pe baza unor activitati de prelucrare automate, inclusiv crearea de profiluri, care produce efecte juridice care privesc persoana vizata sau o afecteaza in mod similar intr-o masura semnificativa;",
                "- dreptul de a va adresa Autoritatii Nationale de Supraveghere a Prelucrarii Datelor cu Caracter Personal sau instantelor competente, in masura in care considerati necesar.",
            ]
        },
        {
            TITLE: "Contact",
            LINES: [
                "Bulevardul Socola nr 3 Bloc D2 Sc A Ap 20 700186 Iasi, Romania, contact@caziere.ro"
            ]
        },
    ]
}

const FAQ = [
    {
        PAGE_TITLE: "Cum procedez pentru obținere acte prin caziere.ro?",
        NO_SUPP_SPACES: true,
        TITLE_FONT_TYPE: 'h4',
        ROW_FONT_TYPE: 'subtitle1',
        DESCRIPTION: [],
        ROWS: [
            {
                LINES: [
                    "Prezența dumneavoastră la sediul nostru nu este necesară. Punem accent pe profesionalism si rapiditate la obtinere cazier judiciar online prin serviciile noastre. Indiferent dacă sunteți cetățeni români și locuiți în București sau în orice alt oraș din România. In images important pentru  cetățeni români  ce nu se pot deplasa si necesita servicii consulare pentru eliberarea duplicatelor actelor fara deplasari. Inclusiv pentru cei din Republica Moldova. Toate detaliile pot fi transmise prin intermediul aplicatiei ONLINE de pe pagina, iar plata se face online prin card bancar.",
                ]
            },
            {
                LINES: [
                    "Noi ne vom ocupa de toate procedurile în numele dumneavoastră, iar după ce toate acestea au fost finalizate, veți primi o copie a cazierului dumneavoastră judiciar, scanată profesional. Fizic primiți prin CURIER RAPID în București și în alte orașe din România sau în străinătate prin DHL RAPID (în orice țară).",
                ]
            },
            {
                LINES: [
                    "Dacă sunteți interesat să utilizați serviciile noastre, vă rugăm să folositi aplicatia online, unde nu există cozi (unde veți trimite și document de identitate)."
                ]
            },
        ]
    },
    {
        PAGE_TITLE: "Acte necesare pentru începerea procedurilor de obtinere a certificatului de cazier judiciar online",
        NO_SUPP_SPACES: true,
        TITLE_FONT_TYPE: 'h4',
        ROW_FONT_TYPE: 'subtitle1',
        DESCRIPTION: [],
        ROWS: [
            {
                LINES: [
                    "Pentru a executa toate operațiunile în numele dumneavoastră (prin reprezentare), trebuie să trimiteți urmatoarele acte necesare pentru obținerea cazierului:",
                    "   – O copie a actului de identitate (a pașaportului pentru românii care locuiesc în afara țării sau au obținut cetățenia acolo);",
                    "   – O poza (selfie) in care tineti acest act in mana;",
                    "   – Contract ce va fi semnat de dumneavoastră online, prin intermediul căruia suntem împuterniciți legal să obținem certificatul de cazier judiciar fără ca dvs să vă deplasați nicăieri. Finalizăm acte necesare, împeună cu cererea tip pentru această procedură. Contractul este disponibil înainte de semnare și pe pagina dvs. de profil dupa logare;"
                ]
            }
        ]
    },
    {
        PAGE_TITLE: "Timp de execuție și documente necesare pentru obtinere cazier judiciar online",
        NO_SUPP_SPACES: true,
        TITLE_FONT_TYPE: 'h4',
        ROW_FONT_TYPE: 'subtitle1',
        DESCRIPTION: [],
        ROWS: [
            {
                LINES: [
                    "În mod normal, obținerea unui certificat de cazier judiciar online durează intre 1-5 ZILE LUCRATOARE de la data solicitării in functie de regimul ales sau la care primim toate documentele solicitate și semnate de către dvs. În cele mai multe cazuri se poate obține în aceeași zi.",
                ]
            },
            {
                LINES: [
                    "În funcție de serviciile implicate, timpul de procesare va fi mai lung dacă solicitați servicii suplimentare de apostilare, supralegalizare sau traducere."
                ]
            },
            {
                LINES: [
                    "Înțelegem că TIMPUL este extrem de important pentru dumneavoastră, de aceea vom face tot posibilul pentru a vă respecta termenele limită și pentru a face livrarea documentelor si procedurile in numele dvs cât mai rapide cu putință. Caziere.ro  eliberează certificat de cazier judiciar online prin avocat prin reprezentare. Dorim să vă invităm să trimiteți o aplicație folosind formularul ONLINE de pe pagina, astfel încât să vă putem ajuta în continuare. Redactăm noi cerere pentru eliberarea certificatului. Odata selectat serviciul final, puteti incarca documentele si efectua plata, fiind mereu la current cu statusul comenzii si fiind anuntat automat prin sms odata ce cazierul judiciar online a fost eliberat. Nu va trebui să faceți o călătorie în România images pentru acest demers."
                ]
            },
            {
                LINES: [
                    "Odată ce plata a fost efectuată, comanda dvs. intră în sistem și nu se poate anula."
                ]
            },
        ]
    },
    {
        PAGE_TITLE: "Acte necesare obtinere cazier judiciar online pentru persoane juridice",
        NO_SUPP_SPACES: true,
        TITLE_FONT_TYPE: 'h4',
        ROW_FONT_TYPE: 'subtitle1',
        DESCRIPTION: [],
        ROWS: [
            {
                LINES: [
                    "În acest caz, persoana juridică trebuia să depună cererea de cazier judiciar la orice unitate sau subunitate de poliție conectată la Sistemul Informatic al Cazierului Judiciar din România, prin reprezentantul său legal, care trebuie să facă dovada împuternicirii.",
                ]
            },
            {
                LINES: [
                    "Pentru obținere acte persoana juridica, desemnatul imputernicit va mai face dovada și a următoarelor acte: dovada calității de persoană juridică; dovada că individul care depune cererea este reprezentantul legal împuternicit; copie după actul de identitate al reprezentantului legal;",
                ]
            },
            {
                LINES: [
                    "Caziere.ro simplifica insă procesul."
                ]
            },
            {
                LINES: [
                    "Eliberare cazier judiciar pentru persoane atât fizice şi juridice"
                ]
            },
            {
                LINES: [
                    "Calculul total este determinat de numărul de documente și de procese care trebuie finalizate, precum și de alte particularități (taxe și impozite unde este cazul). Aceasta se aplică atat în cazul persoanelor fizice, cât și celor juridice."
                ]
            },
            {
                LINES: [
                    "Facturarea se face în LEI iar această factură se trimite automat pe email."
                ]
            },
            {
                LINES: [
                    "PLATA ONLINE: Alegeți cardul bancar care vă oferă cele mai multe opțiuni de plată pentru achizițiile online, cum ar fi VISA și Mastercard, American Express, Discover, și multe altele (primiți pe loc și factura aferentă plății dvs. alegând această metodă de plată instant)"
                ]
            },
            {
                LINES: [
                    "Puteti opta pentru transfer clasic bancar, și veți primi un cont în care să transferați banii folosind ce metodă doriți (mergând la o bancă, aplicații bancare, etc)"
                ]
            },
            {
                LINES: [
                    "Plata se face oricand doriti iar procesul incepe odata ce este efectuata. Veti fi notificat prin email si sms cu privire la orice informatii despre comanda."
                ]
            },
        ]
    }
]

const MAIN_PAGE_CONTENT = [
    {
        NO_SUPP_SPACES: true,
        TITLE_FONT_TYPE: 'h4',
        ROW_FONT_TYPE: 'subtitle1',
        ROWS: [
            {
                LINES: [
                    "Multe activități, inclusiv obținerea unui loc de muncă, susținerea unor examene, înscrierea la anumite cursuri și programe sau dobândirea cetățeniei unei alte țări, necesită verificarea antecedentelor penale ale unei persoane.",
                ]
            },
            {
                LINES: [
                    "Centrul de Documente și Legalizări Caziere.ro, se poate ocupa de toate formalitățile necesare pentru obtinere un certificat de cazier judiciar în numele dumneavoastră, indiferent dacă aveți domiciliul în București, România sau în străinătate. Veți economisi timp și bani, evitând bătăile de cap asociate cu instituțiile publice românești sau cu reprezentanții diplomatici/consulari străini."
                ]
            },
            {
                LINES: [
                    "Acest cazier judiciar se eliberează rapid si usor, cu posibilitatea traducerii si legalizarii."
                ]
            },
        ]
    },
    {
        NO_SUPP_SPACES: true,
        TITLE_FONT_TYPE: 'h4',
        ROW_FONT_TYPE: 'subtitle1',
        ROWS: [
            {
                LINES: [
                    "Oferim servicii complete de obținere a certificatelor de cazier judiciar in numele dvs, precum și de traducere și legalizare (avizare, apostilare sau super-legalizare) a documentelor care pot fi utilizate în orice țară din lume (identitate şi călătorie). Nu este necesar să veniți la birourile noastre pentru depunere sau eliberare documente. Puteți să ni le trimiteți conform termenilor din Contractul de prestari servicii prin orice metodă pe care o alegeți. Noi ne vom ocupa de toate procedurile, iar dvs. veți primi in cel mai scurt timp posibil cazier online sau actele solicitate."
                ]
            },
            {
                LINES: [
                    "Eliberarea cazierului se poate face online și ușor acum în România. Folosind un serviciu online de eliberare a acestor acte oficiale, puteți uita de statul la cozi și de timpul pierdut. Timp pierdut înseamnă și bani pierduți."
                ]
            }
        ]
    },
]

let toExport = {
    TERMS_AND_CONDITIONS: TERMS_AND_CONDITIONS,
    PRIVACY_POLICY: PRIVACY_POLICY,
    FAQ: FAQ,
    MAIN_PAGE_CONTENT: MAIN_PAGE_CONTENT,
};
export default toExport;