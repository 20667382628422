import {Icon} from '@iconify/react';
import {sentenceCase} from 'change-case';
import * as React from 'react';
import {Component, useEffect} from 'react';
// material
import {
    Box, Button,
    Card,
    Checkbox,
    Container, Grid,
    IconButton,
    Input,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    ToggleButton,
    Typography
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserListToolbar} from '../components/_dashboard/user';
//
import * as CommunicationService from "../services/CommunicationService";
import downloadOutlined from "@iconify/icons-ant-design/download-outlined";
import deletedFileOutlined from "@iconify/icons-ant-design/delete-row-outlined";
import * as LogicService from "../services/LogicService";
import {STATUSES} from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import AccessSelect from "./AccessSelect";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import OrderMoreMenu from "../components/_dashboard/user/OrderMoreMenu";
import * as Languages from "../constants/Languages";
import * as Countries from "../constants/Countries";
import ReportToolbar from "../components/_dashboard/user/ReportToolbar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {fDateTimeForBackend} from "../utils/formatTime";
import AppInfoOne from "../components/authentication/order/AppInfoOne";
import Constants from "../Constants";
import AppInfoTwo from "../components/authentication/order/AppInfoTwo";
import AppInfoThree from "../components/authentication/order/AppInfoThree";
import {getEFacturaMessages, getEFacturaProblematicOrders} from "../services/CommunicationService";
import ConfirmModal from "../components/_dashboard/user/ConfirmModal";
import APIConstants from "../constants/APIConstants";
import CommunicationLogsEFactura from "./CommunicationLogsEFactura";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'cif', label: 'CIF', alignRight: false},
    {id: 'dateMessageReceived', label: 'Data', alignRight: false},
    {id: 'idSolicitare', label: 'ID Solicitare', alignRight: false},
    {id: 'tip', label: 'Raspuns', alignRight: false},
    {id: 'environment', label: 'Environment', alignRight: false},
    {id: 'detalii', label: 'Detalii', alignRight: false},
    {id: 'invoice_id', label: 'Invoice Id', alignRight: false},
    {id: 'download', label: 'Download Response', alignRight: false}
];
const TABLE_HEAD_AUX = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'cif', label: 'CIF', alignRight: false},
    {id: 'dateMessageReceived', label: 'Data', alignRight: false},
    {id: 'idSolicitare', label: 'ID Solicitare', alignRight: false},
    {id: 'tip', label: 'Raspuns', alignRight: false},
    {id: 'environment', label: 'Environment', alignRight: false},
    {id: 'detalii', label: 'Detalii', alignRight: false},
    {id: 'download', label: 'Download Response', alignRight: false}
];
const TABLE_HEAD_AUX_2 = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'executionStatus', label: 'ExecutionStatus', alignRight: false},
    {id: 'indexUpload', label: 'Index', alignRight: false},
    {id: 'dateResponse', label: 'Data', alignRight: false},
    {id: 'env', label: 'Environment', alignRight: false}
];

export default class EFactura extends Component {

    constructor() {
        super()
        this.state = {
            detailsModalIsOpen: false,
            tableHead: TABLE_HEAD,
            isLawyer: true,
            orders: [],
            urgentOrders: [],
            problematicOrdersRetreived: false,
            laterAddedDeliveryOrders: [],
            lawyers: [],
            statuses: [],
            transactions: [
                {
                    label: 'Verified',
                    value: true
                },
                {
                    label: 'Not verified',
                    value: false
                }
            ],
            page: 0,
            order: 'desc',
            selected: [],
            orderBy: 'dateMessageReceived',
            filterBy: null,
            filterName: null,
            filterLawyer: null,
            filterStatus: null,
            filterTransaction: null,
            rowsPerPage: 10,
            totalItems: 0,
            totalPages: 0,
            refreshModalOpen: false,
            orderRetriggerModal: false,
        }
    }

    setOrders = (val) => {
        this.setState({orders: val})
    }
    setRefreshModalOpen = (val) => {
        this.setState({refreshModalOpen: val})
    }
    setOrderRetriggerModalOpen = (val) => {
        this.setState({orderRetriggerModal: val})
    }
    setLaterAddedDeliveryOrders = (val) => {
        this.setState({laterAddedDeliveryOrders: val, problematicOrdersRetreived: true})
    }

    setPage = (val) => {
        this.setState({page: val}, () => this.getOrders(true))
    }

    setOrder = (val) => {
        this.setState({order: val}, () => this.getOrders(true))
        // this.setState({order: val})
    }

    setSelected = (val) => {
        this.setState({selected: val})
    }

    setOrderBy = (val) => {
        this.setState({orderBy: val}, () => {
            // this.getOrders()
        })
    }

    setFilterName = (type, val) => {
        this.clearAllFilters(
            () => {
                this.setState({
                    filterBy: type,
                    filterName: val
                }, () => this.getOrders(true, type, val))
            }
        )
    }

    setFilterLawyer = (type, val) => {
        this.clearAllFilters(
            () => {
                this.setState({
                    filterBy: type,
                    filterLawyer: val
                }, () => this.getOrders(true, type, val))
            })
    }

    setFilterStatus = (type, val) => {
        this.clearAllFilters(
            () => {
                this.setState({
                    filterBy: type,
                    filterStatus: val
                }, () => this.getOrders(true, type, val))
            })
    }

    clearAllFilters = (thenMethod) => {
        this.setState({
            filterBy: null,
            filterName: null,
            filterLawyer: null,
            filterStatus: null,
            filterTransaction: null
        }, thenMethod)
    }

    getFilterValueBy = (filterBy) => {
        if (filterBy == undefined || filterBy == null) {
            filterBy = this.state.filterBy
        }
        let filterValue = null;

        switch (filterBy) {
            case 'search' : {
                filterValue = this.state.filterName;
                break;
            }
            case 'status' : {
                filterValue = this.state.filterStatus;
                break;
            }
            case 'lawyer' : {
                filterValue = this.state.filterLawyer;
                break;
            }
            case 'transaction' : {
                filterValue = this.state.filterTransaction;
                break;
            }
            default: {
                break;
            }
        }
        return filterValue;
    }


    setRowsPerPage = (val) => {
        this.setState({rowsPerPage: val}, () => this.getOrders(true))
    }

    componentDidMount() {
        let Auth = CommunicationService.getUser();
        let userType = CommunicationService.getUserType();
        let isModeratorOrGreater = Auth !== null && (userType === 'ROLE_ADMIN' || userType === 'ROLE_MODERATOR')
        this.setState({
            isLawyer: !isModeratorOrGreater
        }, () => {
            this.getOrders();
        })
    }

    getOrders(force = false, filterBy = this.state.filterBy, filterName = this.state.filterName) {
        if (force || this.state.orders.length === 0) {
            if (filterBy == undefined || filterBy == null) {
                filterBy = this.state.filterBy;
            }
            if (filterName == undefined || filterName == null) {
                filterName = this.getFilterValueBy(this.state.filterBy)
            }
            CommunicationService.getEFacturaMessages(this.state.page, this.state.rowsPerPage, this.state.orderBy,
                this.state.order, filterBy, filterName, false)
                .then((response) => {
                    this.setState({
                        totalItems: response.totalItems,
                        totalPages: response.totalPages
                    })
                    this.setOrders(response.responses)
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

            CommunicationService.getEFacturaProblematicOrders()
                .then((response) => {
                    this.setLaterAddedDeliveryOrders(response.map(r => this.mapRequestOrder(r)))
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
        }
    }


    render() {

        const ORDERLIST = this.state.orders;
        const page = this.state.page;
        const order = this.state.order;
        const selected = this.state.selected;
        const orderBy = this.state.orderBy;
        const filterName = this.state.filterName;
        const rowsPerPage = this.state.rowsPerPage;

        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === 'asc';
            this.setOrder(isAsc ? 'desc' : 'asc');
            this.setOrderBy(property);
        };

        const handleSelectAllClick = (event) => {
            if (event.target.checked) {
                const newSelecteds = ORDERLIST.map((n) => n.name);
                this.setSelected(newSelecteds);
                return;
            }
            this.setSelected([]);
        };

        const handleChangePage = (event, newPage) => {
            this.setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            this.setRowsPerPage(parseInt(event.target.value, 10));
            this.setPage(0);
        };

        const handleFilterByName = (text) => {
            this.setFilterName('search', text);
        };

        const filteredUsers = ORDERLIST;

        const isUserNotFound = filteredUsers.length === 0;

        let LATER_ADDED_DELIVERY_ORDERS = this.state.laterAddedDeliveryOrders;
        let laterAddedDeliveryOrdersExist = LATER_ADDED_DELIVERY_ORDERS.length > 0;

        return (
            <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">

                <Container maxWidth={"1600px"}>
                    <ConfirmModal open={this.state.refreshModalOpen}
                                  handleConfirm={() => {
                                      CommunicationService.forceRefresh()
                                          .then((response) => {
                                              LogicService.setAlertMessageAndSeverity({
                                                  message: response.message,
                                                  key: 'anaf_cui_not_found',
                                                  severity: 'error'
                                              })
                                          })
                                          .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
                                      this.setRefreshModalOpen(false);
                                  }}
                                  handleClose={() => this.setRefreshModalOpen(false)}
                                  title={"Confirmați acțiunea!"}
                                  content={"Actiunea va interoga baza de date ANAF pentru gasirea de modificari."}/>
                    <ConfirmModal open={this.state.orderRetriggerModal}
                                  handleConfirm={() => {
                                      CommunicationService.forceSendOrders()
                                          .then((response) => {
                                              LogicService.setAlertMessageAndSeverity({
                                                  message: response.message,
                                                  key: 'anaf_cui_not_found',
                                                  severity: 'error'
                                              })
                                          })
                                          .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
                                      this.setOrderRetriggerModalOpen(false);
                                  }}
                                  handleClose={() => this.setOrderRetriggerModalOpen(false)}
                                  title={"Confirmați acțiunea!"}
                                  content={"Actiunea va trimite facturile din toate orderele cu probleme la ANAF."}/>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            E Factura
                        </Typography>
                    </Stack>

                    <Card sx={{marginBottom: '15px'}}>
                        <Grid item xs={12} sm={4} md={2}>
                            <Stack direction="row" alignItems="right" spacing={2}
                                   sx={{padding: '15px', display: 'flex', width: "500px"}}>
                                <Button variant="contained"
                                        sx={{width: '100%'}}
                                        onClick={() => {
                                            this.setRefreshModalOpen(true);
                                        }}>Refresh mesaje</Button>

                                <Button variant="contained"
                                        sx={{width: '100%'}}
                                        onClick={() => {
                                            this.setOrderRetriggerModalOpen(true);
                                        }}>Trimitere ordere problematice</Button>
                            </Stack>
                        </Grid>
                    </Card>


                    <Card sx={{marginBottom: '15px'}}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{color: 'white'}}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={laterAddedDeliveryOrdersExist ? {color: "white", background: 'blue'} : {}}
                            >
                                <Typography>Problematic orders ({this.state.problematicOrdersRetreived ? LATER_ADDED_DELIVERY_ORDERS.length : 'Loading ...'})</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{background: '#ffebee'}}>
                                <Scrollbar>
                                    <TableContainer sx={{minWidth: 800}}>
                                        <Table>
                                            <TableBody>
                                                {LATER_ADDED_DELIVERY_ORDERS.map((row) => this.mapOldOrderTo(row, selected))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>
                            </AccordionDetails>
                        </Accordion>
                    </Card>


                    <Card sx={{marginBottom: '20px'}}>

                        <Scrollbar>
                            <TableContainer sx={{minWidth: 800}}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={this.state.tableHead}
                                        rowCount={this.state.totalItems}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredUsers.map((row) => this.mapOrderTo(row, selected))}
                                    </TableBody>
                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                    <SearchNotFound searchQuery={filterName}/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.totalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>

                    <CommunicationLogsEFactura/>
                </Container>
            </Page>
        );
    }


    mapRequestOrder = (order) => {
        let user = order.user;
        let name = user.firstName + ' ' + user.lastName;
        order.documents = []
        let orderStatus = order.status;
        let color = (STATUSES[orderStatus.toUpperCase()] || {}).color;
        let orderMapped = {
            id: order.id,
            name: name,
            cui: user.cui,
            userType: order.isFor === 'PERSON' ? 'PF' : 'SRL',
            transactionVerified: order.transactionVerified,
            status: orderStatus,
            createdAt: new Date(order.createdAt).toLocaleString(),
            price: order.price?.toString(),
            color: color,
            efacturaMessageResponses: order.efacturaMessageResponses,
            efacturaResponses: order.efacturaResponses,
        };

        return orderMapped
    }

    mapOldOrderTo(row, selected) {
        const {
            id,
            name,
            cui,
            userType,
            price,
            status,
            transactionVerified,
            color,
            createdAt,
            efacturaMessageResponses,
            efacturaResponses
        } = row;
        const isItemSelected = false
        return this.getOldOrderRow(id, isItemSelected, name, cui, userType, color, status, transactionVerified, price, createdAt, efacturaMessageResponses, efacturaResponses, row);
    }

    getOldOrderRow(id, isItemSelected, name, cui, userType, color, status, transactionVerified, price, createdAt,
                   efacturaMessageResponses = [], efacturaResponses = [], row) {
        return (
            <Accordion sx={{display: 'table-row', width: '100%'}}>
                <AccordionSummary sx={{display: 'block' , background: 'white' , width: '100%'}}>
                    <TableRow
                        sx={{ display: 'block', background: 'white', width: '100%'}}
                    >

                        <TableCell padding="checkbox">

                        </TableCell>
                        <TableCell align="left">{id}</TableCell>

                        <TableCell align="left" sx={{fontSize: '0.5 rem'}}>
                            <Label
                                variant="ghost"
                                color={color}
                                sx={{height: '35px', fontSize: '10px !important'}}
                            >
                                {status}

                            </Label>
                        </TableCell>
                        {
                            this.state.isLawyer ? null :
                                <TableCell align="left">
                                    <Label
                                        variant="ghost"
                                        color={transactionVerified ? 'success' : 'error'}
                                    >
                                        {price}
                                    </Label>
                                </TableCell>

                        }
                        <TableCell align="left">{createdAt}</TableCell>

                        <TableCell align="left" sx={{minWidth: '150px'}}>{cui}</TableCell>
                        <TableCell align="left" sx={{minWidth: '150px'}}>{name}</TableCell>
                    </TableRow>
                </AccordionSummary>
                <AccordionDetails sx={{display: 'table-row', width: '100%'}}>
                    <TableRow>
                        <Table>
                            <UserListHead
                                headLabel={TABLE_HEAD_AUX}
                            />
                            <TableBody>
                                {efacturaMessageResponses?.map((row) => this.mapOrderTo(row, []))}
                            </TableBody>
                        </Table>
                    </TableRow>

                    <TableRow>
                        <Table>
                            <UserListHead
                                headLabel={TABLE_HEAD_AUX_2}
                            />
                            <TableBody>
                                {
                                    efacturaResponses?.map((row) => {
                                        return <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >

                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left">{row.id}</TableCell>
                                            <TableCell align="left">{row.executionStatus}</TableCell>
                                            <TableCell align="left">{row.indexUpload}</TableCell>
                                            <TableCell align="left">{new Date(row.dateResponse).toLocaleString()}</TableCell>
                                            <TableCell align="left">{row.environment}</TableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableRow>
                </AccordionDetails>
            </Accordion>
        );
    }

    mapOrderTo(row, selected) {
        const {
            id, cif, dateMessageReceived, idSolicitare, detalii, tip, environment, efacturaResponse
        } = row;

        const isItemSelected = selected.indexOf(name) !== -1;


        return this.getOrderRow(id, isItemSelected, cif, dateMessageReceived, idSolicitare, detalii, tip, environment, efacturaResponse);
    }

    downloadFileFunc(cif, id) {
        let url = APIConstants.API.HOST_AND_PORT + APIConstants.API.PATHS.E_FACTURA.DOWNLOAD(cif, id);
        CommunicationService.downloadFile(url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "result_" + Date.now() + ".zip";
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    getOrderRow(id, isItemSelected, cif, dateMessageReceived, idSolicitare, detalii, tip, environment, efacturaResponse) {
        let invoiceId = null;
        if (efacturaResponse != null && efacturaResponse.invoiceDetails != null) {
            invoiceId = efacturaResponse.invoiceDetails.id;
        }
        return (
            <TableRow
                hover
                key={id}
                tabIndex={-1}
                role="checkbox"
                selected={isItemSelected}
                aria-checked={isItemSelected}
            >

                <TableCell align="left"></TableCell>
                <TableCell align="left">{id}</TableCell>
                <TableCell align="left">{cif}</TableCell>
                <TableCell align="left">{new Date(dateMessageReceived).toLocaleString()}</TableCell>
                <TableCell align="left">{idSolicitare}</TableCell>
                <TableCell align="left">{tip}</TableCell>
                <TableCell align="left">{environment}</TableCell>
                <TableCell align="left">{detalii}</TableCell>
                <TableCell align="left">{invoiceId}</TableCell>
                <TableCell align="left">
                    <IconButton
                        onClick={() => this.downloadFileFunc(cif, id)}
                    >
                        <Icon icon={ downloadOutlined} width={24} height={24}/>
                    </IconButton>
                </TableCell>
                {/*<TableCell align="left">{message}</TableCell>*/}
            </TableRow>
        );
    }
}
